exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-archive-date-js-content-file-path-blog-golang-slice-internals-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__archive_date}.js?__contentFilePath=/opt/build/repo/blog/golang-slice-internals/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-archive-date-js-content-file-path-blog-golang-slice-internals-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-archive-date-js-content-file-path-blog-you-might-not-need-kubernetes-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__archive_date}.js?__contentFilePath=/opt/build/repo/blog/you-might-not-need-kubernetes/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-archive-date-js-content-file-path-blog-you-might-not-need-kubernetes-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-golang-slice-internals-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/golang-slice-internals/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-golang-slice-internals-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-graceful-termination-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/graceful-termination/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-graceful-termination-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-you-might-not-need-kubernetes-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/you-might-not-need-kubernetes/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-you-might-not-need-kubernetes-index-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

